

    import $request from "@/modules/request/"

    export default {
            
        until: new Date().getTime(),
        items: [],
        banners: [],
        individual_items: [],
        individual_until: new Date().getTime(),
            
        get(hard_reload = false) {
            // if(!hard_reload && this.items.length > 0 ) return;
            
            $request.get("contracts.getSpecialOffers").then( rsp => {
                this.until = rsp.until;
                this.individual_until = rsp.individual_until,
                this.items = rsp.items;
                this.individual_items = rsp.individual_items;
                this.banners = rsp.banners;
            });
        },   

        checkAndActive(cb = null) {
            $request.post("contracts.checkSpecialOffers", {
                offer: localStorage.getItem('offer')
            }).then( rsp => {
                localStorage.removeItem('offer');
                if (cb) cb();
            });
        }
            
    }