<template>
    <div class="data-form">

        <center>
            <div class="modal-title mb-2">
                <b-img
                src="~@/assets/images/icons/glossy.png"
                fluid
                alt="glossy"
                style="width: 64px; height: 64px;"
            ></b-img>
            </div>

            <i18n path="modals.confirm-email.header" tag="p" style="font-size: 22px; font-weight: 500; line-height: 24px">
                <br>
            </i18n>

            <b-button @click="close" variant="primary" block size="lg" style="border-radius: 16px; margin-top: 22px;">
                    <span>
                        {{$t('modals.confirm-email.btn')}}
                    </span>

            </b-button>
        </center>
      
    </div>
</template>

<script>
export default {
    data() {
        return {
            is_loading: false,
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    },
    mounted() {
        localStorage.removeItem('confirmed:email');
    }
}
</script>

<style lang="scss" scoped>

</style>
