<template>

    <b-card v-if="special.individual_items.length > 0" bg-variant="transparent" no-body>
        
        <b-card-header>
            <b-card-title class="d-flex align-items-center">
                
                <div style="margin-right: 8px;">{{$t('offer-card.offers-for-you')}}</div>
                                
                    <countdown :end-time="special.individual_until * 1000" ref="countdown" @finish="finish()">
                    
                    <template
                      v-slot:process="countdown">
                        <div class="d-flex counter">
                            <div>{{ countdown.timeObj.h }}</div>
                                <span class="text-muted">:</span>
                            <div>{{ countdown.timeObj.m }}</div>
                                <span class="text-muted">:</span>
                            <div>{{ countdown.timeObj.s }}</div>
                        </div>
                    </template>
            
                </countdown>
                
                <div class="text-muted" style="font-size: 12px; font-weight: 400; margin-left: 6px;">
                    {{$t('offer-card.offer')}}<br>{{$t('offer-card.valid')}} 
                </div>
                
            </b-card-title>
        </b-card-header>
        
        
        <b-card-body style="padding: 0; position: relative;">
            <b-button @click="slidePrev()" :class="isBeginning ? 'hidden' : ''" variant="light" style="position: absolute; width: 35px; height: 35px; border-radius: 55px; padding: 0; top: calc(50% - 17px); left: 0; z-index: 99;">
                <feather-icon icon="ChevronLeftIcon" size="17" />
            </b-button>
            <showAt breakpoint="large">
                <swiper ref="swiper" :options="swiperOptionsDesktop" @slideChange="slideChange">
                    <swiper-slide v-for="(item, index) in special.individual_items" :key="index">
                        <offer-card :item="item" />
                    </swiper-slide>
                </swiper>
            </showAt>
            <showAt breakpoint="medium">
                <swiper ref="swiper" :options="swiperOptionsTablet" @slideChange="slideChange">
                    <swiper-slide v-for="(item, index) in special.individual_items" :key="index">
                        <offer-card :item="item" />
                    </swiper-slide>
                </swiper>
            </showAt>
           <b-button @click="slideNext()" :class="isEnd ? 'hidden' : ''" variant="light" style="position: absolute; width: 35px; height: 35px; border-radius: 55px; padding: 0; top: calc(50% - 17px); right: 0; z-index: 99;">
                <feather-icon icon="ChevronRightIcon" size="17" />
            </b-button>
        </b-card-body>
        
    </b-card>
    
</template>

<style>
    
    .counter {
        align-items: center;
    }
    
    .counter div {
        margin: 0 2px;
        color: #FFF;
        background-color: var(--theme-color);
        width: 27px; 
        height: 27px;
        text-align: center;
        line-height: 27px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
       
    }
</style>

<script>
    
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import special from "@/modules/contents/special"
    import OfferCard from "@/components/OfferCard"
    
    export default {

        data() {
            return {
                special,
                swiperOptionsDesktop: {
                    slidesPerView: 3,
                    slidesOffsetBefore: 0,
                },
                swiperOptionsTablet: {
                    slidesPerView: 2,
                    slidesOffsetBefore: 0,
                },

                isEnd: false,
                isBeginning: true
            }
        },
        methods: {
            order( item ) {
                this.$router.push({
                    name: "user-contracts-create",
                    query: {
                        'with-offer': item.id
                    }
                });
            },

            finish() {
                this.special.get(true);
            },

            slideNext() {
                this.swiper.slideNext()
            },

            slidePrev() {
                this.swiper.slidePrev()
            },

            slideChange() {
                this.isEnd = this.swiper.isEnd
                this.isBeginning = this.swiper.isBeginning
            }
        },
        components: {
            Swiper,
            SwiperSlide,
            OfferCard
        },
        computed: {
            swiper() {
                return this.$refs.swiper.swiperInstance;
            }
        },
        watch: {
            'special.items' : function(val) {
                if (this.special.individual_items.length < 4) this.isEnd = true
                else  this.isEnd = false
            }
        },
        mounted() {
        }

    }

</script>