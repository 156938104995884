<template>
    
    <div class="training-card">
        <div class="training-card__header">
            <img alt="" :src="stage.img" style="width: 100%" />
        </div>

        <div class="training-card__body">
            <span style="font-size: 16px; line-height: 24px;"> {{$t(stage.text)}} </span>

            <div class="d-flex mt-2" style="color: #426FF6; justify-content: space-between;">
                <div>{{stage.i}}<span style="opacity: 0.5"> / 6</span></div>
                <div class="d-flex" style="cursor: pointer" @click="next">{{$t('next')}} 
                    <div style="background-color: #426FF6; border-radius: 100px; padding: 0px 3px; margin-left: 6px">
                        <feather-icon
                        icon="ChevronDownIcon"
                        size="14"
                        style="cursor:pointer; transform: rotate(-90deg); color: #FFF;"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import offers from "@/modules/contents/offers"

import A from "@/assets/images/training/mobile/s_1.png";
import B from "@/assets/images/training/mobile/s_2.png";
import C from "@/assets/images/training/mobile/s_3.png";
import D from "@/assets/images/training/mobile/s_4.png";
import E from "@/assets/images/training/mobile/s_5.png";
import F from "@/assets/images/training/mobile/s_6.png";

export default {
    data() {
        return {
            offers,
            stage: null,
            stages: [
                {
                    i: 1,
                    img: A,
                    text: 'modals.training.cloud'
                },
                {
                    i: 2,
                    img: B,
                    text: 'modals.training.miners'
                },
                {
                    i: 3,
                    img: C,
                    text: 'modals.training.wallet'
                },
                {
                    i: 4,
                    img: D,
                    text: 'modals.training.news'
                },  
                {
                    i: 5,
                    img: E,
                    text: 'modals.training.settings'
                },
                {
                    i: 6,
                    img: F,
                    text: 'modals.training.help'
                }
            ],
            A, B, C, D, E, F,
        }
    },
    methods: {
        next() {

            if (this.stage.i < 6) {
                this.stage = this.stages.find(s => s.i === this.stage.i + 1);
            } else {
                this.close();
                if (offers.items.length > 0) this.show('offer') 
            }

        },
        show(modal) {
            this.$emit('show', modal);
        },
        close() {
            this.$emit('close');
        }
    },
    mounted() {
        this.stage = this.stages[0];
        this.$user.useWelcome();
    }
}
</script>

<style lang="scss" scoped>
    .training-card {
        display: flex;
        flex-direction: column;
        
        &__body {
            padding: 40px;
            padding-bottom: 90px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
</style>