<template>
    <b-card v-if="dataSource.items.length > 0" bg-variant="transparent" no-body>
        
        <b-card-header>
            <b-card-title class="d-flex align-items-center">
                
                <div @click="$router.push({ name: 'user-news' })" style="margin-right: 8px; cursor: pointer;">
                    {{$t('news.news')}} <feather-icon icon="ChevronRightIcon" size="17" style="margin-top: -2px;"/> 
                </div>
                
            </b-card-title>
        </b-card-header>
        
        <b-card-body style="padding: 0">
            <swiper ref="swiper" :options="swiperOptions" >
                <swiper-slide v-for="item in dataSource.items">
                    <news-view :item='item' />
                </swiper-slide>
            </swiper>
        </b-card-body>
        
    </b-card>
</template>

<script>
    
    import NewsView from "@/components/NewsView"
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {

        data() {
            return {
                news: null,
                swiperOptions: {
                    slidesPerView: 1,
                    slidesOffsetBefore: 0
                },
            }
        },
        props: {
            dataSource: Object
        },
        methods: {
           
        },
       
        components: {
            NewsView,
            Swiper,
            SwiperSlide
        },
        watch: {

        },
        mounted() {

        }

    }

</script>