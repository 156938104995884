<template>
  <div class="training">
    <transition name="fade">
      <div
        v-if="stage == 1"
        style="top: 100px;"
        class="training__card"
      >
        <div>
          <div
            class="d-flex training__title-block"
          >
            <div class="menu-icon icon-cloud"></div>
            <span
              class="menu-title text-truncate training__title"
              >{{$t('navigation.cloud')}}</span
            >
          </div>
        </div>
        <div
          class="training__card-body"
        >
          <div class="arrow-l"></div>
          <div class="mb-1">
            <div style="text-align: end;">
              <a data-action="close" @click="close">
                <feather-icon
                  icon="XIcon"
                  size="14"
                  style="background-color: #9ca2b140; border-radius: 50px; color: #9CA2B1; padding: 1px"
                />
              </a>
            </div>
            <div class="training__text">
             {{$t('modals.training.cloud')}}
            </div>
          </div>
          <div
            class="d-flex"
            style="color: #426FF6; justify-content: space-between;"
          >
            <div>{{ stage }}<span style="opacity: 0.5"> / 6</span></div>
            <div class="d-flex" style="cursor: pointer" @click="next">
              {{$t('next')}}
              <div
                class="training__f-icon-block"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="14"
                  style="cursor:pointer; transform: rotate(-90deg); color: #FFF;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="stage == 2"
        style="top: 148px;"
        class="training__card"

      >
        <div>
          <div
            class="d-flex training__title-block"
          >
            <div class="menu-icon icon-mining"></div>
            <span
              class="menu-title text-truncate training__title"
              >{{$t('navigation.miners')}}</span
            >
          </div>
        </div>
        <div
          class="training__card-body"
        >
          <div class="arrow-l"></div>
          <div class="mb-1">
            <div style="text-align: end;">
              <a data-action="close" @click="close">
                <feather-icon
                  icon="XIcon"
                  size="14"
                  style="background-color: #9ca2b140; border-radius: 50px; color: #9CA2B1; padding: 1px"
                />
              </a>
            </div>
            <div class="training__text">
              {{$t('modals.training.miners')}}
            </div>
          </div>
          <div
            class="d-flex"
            style="color: #426FF6; justify-content: space-between;"
          >
            <div>{{ stage }}<span style="opacity: 0.5"> / 6</span></div>
            <div class="d-flex" style="cursor: pointer" @click="next">
              {{$t('next')}}
              <div
                class="training__f-icon-block"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="14"
                  style="cursor:pointer; transform: rotate(-90deg); color: #FFF;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="stage == 3"
        style="top: 198px;"
        class="training__card"
      >
        <div>
          <div
            class="d-flex training__title-block"
          >
            <div class="menu-icon icon-wallet"></div>
            <span
              class="menu-title text-truncate training__title"
              >{{$t('navigation.wallet')}}</span
            >
          </div>
        </div>
        <div
          class="training__card-body"
        >
          <div class="arrow-l"></div>
          <div class="mb-1">
            <div style="text-align: end;">
              <a data-action="close" @click="close">
                <feather-icon
                  icon="XIcon"
                  size="14"
                  style="background-color: #9ca2b140; border-radius: 50px; color: #9CA2B1; padding: 1px"
                />
              </a>
            </div>
            <div class="training__text">
             {{$t('modals.training.wallet')}}
            </div>
          </div>
          <div
            class="d-flex"
            style="color: #426FF6; justify-content: space-between;"
          >
            <div>{{ stage }}<span style="opacity: 0.5"> / 6</span></div>
            <div class="d-flex" style="cursor: pointer" @click="next">
              {{$t('next')}}
              <div
                class="training__f-icon-block"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="14"
                  style="cursor:pointer; transform: rotate(-90deg); color: #FFF;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="stage == 4"
        style="top: 248px;"
        class="training__card"
      >
        <div>
          <div
            class="d-flex training__title-block"
          >
            <div class="menu-icon icon-news"></div>
            <span
              class="menu-title text-truncate training__title"
              >{{$t('navigation.news')}}</span
            >
          </div>
        </div>
        <div
          class="training__card-body"
        >
          <div class="arrow-l"></div>
          <div class="mb-1">
            <div style="text-align: end;">
              <a data-action="close" @click="close">
                <feather-icon
                  icon="XIcon"
                  size="14"
                  style="background-color: #9ca2b140; border-radius: 50px; color: #9CA2B1; padding: 1px"
                />
              </a>
            </div>
            <div class="training__text">
             {{$t('modals.training.news')}}
            </div>
          </div>
          <div
            class="d-flex"
            style="color: #426FF6; justify-content: space-between;"
          >
            <div>{{ stage }}<span style="opacity: 0.5"> / 6</span></div>
            <div class="d-flex" style="cursor: pointer" @click="next">
              {{$t('next')}}
              <div
                class="training__f-icon-block"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="14"
                  style="cursor:pointer; transform: rotate(-90deg); color: #FFF;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="stage == 5"
        style="top: 418px;"
        class="training__card"
      >
        <div>
          <div
            class="d-flex training__title-block"
          >
            <div class="menu-icon icon-settings"></div>
            <span
              class="menu-title text-truncate training__title"
              >{{$t('navigation.settings')}}</span
            >
          </div>
        </div>
        <div
          class="training__card-body"
        >
          <div class="arrow-l"></div>
          <div class="mb-1">
            <div style="text-align: end;">
              <a data-action="close" @click="close">
                <feather-icon
                  icon="XIcon"
                  size="14"
                  style="background-color: #9ca2b140; border-radius: 50px; color: #9CA2B1; padding: 1px"
                />
              </a>
            </div>
            <div class="training__text">
             {{$t('modals.training.settings')}}
            </div>
          </div>
          <div
            class="d-flex"
            style="color: #426FF6; justify-content: space-between;"
          >
            <div>{{ stage }}<span style="opacity: 0.5"> / 6</span></div>
            <div class="d-flex" style="cursor: pointer" @click="next">
              {{$t('next')}}
              <div
                class="training__f-icon-block"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="14"
                  style="cursor:pointer; transform: rotate(-90deg); color: #FFF;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="stage == 6"
        style="bottom: 10px; width: 100%"
        class="training__card"
      >
        <div
          style="margin-left: 140px; margin-right: 45px; max-height: 130px; margin-top: 18px"
          class="training__card-body"
        >
          <div class="arrow-r"></div>
          <div class="mb-1">
            <div style="text-align: end;">
              <a data-action="close" @click="close">
                <feather-icon
                  icon="XIcon"
                  size="14"
                  style="background-color: #9ca2b140; border-radius: 50px; color: #9CA2B1; padding: 1px"
                />
              </a>
            </div>
            <div class="training__text">
              {{$t('modals.training.help')}}
            </div>
          </div>
          <div
            class="d-flex"
            style="color: #426FF6; justify-content: space-between;"
          >
            <div>{{ stage }}<span style="opacity: 0.5"> / 6</span></div>
            <div class="d-flex" style="cursor: pointer" @click="next">
              {{$t('next')}}
              <div
                class="training__f-icon-block"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="14"
                  style="cursor:pointer; transform: rotate(-90deg); color: #FFF;"
                />
              </div>
            </div>
          </div>
        </div>
        <help-card style="flex:1; margin-right: 50px; margin-bottom: 31px" />
      </div>
    </transition>
  </div>
</template>

<script>
import HelpCard from "../widgets/help.desktop";
import offers from "@/modules/contents/offers"

export default {
  props: {
    offers,
    stage: { type: Number, default: 1 },
  },
  methods: {
    next() {
      if (this.stage === 6) {
        this.close();
        if (offers.items.length > 0) {
          this.show("offer");
        }
        return;
      }

      this.$emit("changeStage", () => {
        this.scroll();
      });
    },
    scroll() {
      this.$emit("scroll");
    },
    show(modal) {
      this.$emit("show", modal);
    },
    close() {
      this.$emit("close");
    },
  },
  components: {
    HelpCard,
  },
  mounted() {
    this.$user.useWelcome();
  },
};
</script>

<style lang="scss" scoped>
.training {
  &__card {
    position: fixed;
    display: flex; 
    flex-direction: row; 
  }
  &__text {
    padding-right: 15px;
    margin-top: -10px;
    color: #000;
  }
  &__title-block {
    background-color:#FFF; 
    width: 193px; 
    padding: 10px 15px;
    border-radius: 10px;
    margin-right: 30px; 
    margin-top: 22px
  }
  &__title {
    margin-left: 10px; 
    line-height: 20px; 
    margin-top: 3px; 
    color: #000;
  }
  &__card-body {
    background-color:#FFF; 
    max-width: 360px; 
    min-width: 360px; 
    padding: 10px 15px; 
    border-radius: 16px;
  }
  &__f-icon-block {
    background-color: #426FF6; 
    border-radius: 100px; 
    padding: 0px 3px; 
    margin-left: 6px
  }
}

.arrow-l {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 215px;
  top: 32px;
  transform: rotate(45deg);
  background-color: #fff;
  border-radius: 4px;
}

.arrow-r {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 489px;
  top: 53px;
  transform: rotate(45deg);
  background-color: #fff;
  border-radius: 4px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
