<template>
    <b-card v-if="dataSource.items.length > 0" bg-variant="transparent" no-body>
        
        <b-card-header>
            <b-card-title class="d-flex align-items-center">
                
                <div @click="$router.push({ name: 'user-news' })" style="margin-right: 8px; cursor: pointer;">
                    {{$t('news.news')}} <feather-icon icon="ChevronRightIcon" size="17" style="margin-top: -2px;"/> 
                </div>
                
            </b-card-title>
        </b-card-header>
        
        <b-card-body style="padding: 0; position: relative;">
            <b-button @click="slidePrev()" :class="isBeginning ? 'hidden' : ''" variant="light" style="position: absolute; width: 35px; height: 35px; border-radius: 55px; padding: 0; top: calc(50% - 17px); left: 0; z-index: 99;">
                <feather-icon icon="ChevronLeftIcon" size="17" />
            </b-button>
            <swiper ref="swiper" :options="swiperOptions" @slideChange="slideChange">
                <swiper-slide v-for="item in dataSource.items">
                    <news-view :item='item' />
                </swiper-slide>
            </swiper>
            <b-button @click="slideNext()" :class="isEnd ? 'hidden' : ''" variant="light" style="position: absolute; width: 35px; height: 35px; border-radius: 55px; padding: 0; top: calc(50% - 17px); right: 0; z-index: 99;">
                <feather-icon icon="ChevronRightIcon" size="17" />
            </b-button>
        </b-card-body>
        
    </b-card>
</template>

<script>
    
    import NewsView from "@/components/NewsView"
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {

        data() {
            return {
                news: null,
                swiperOptions: {
                    slidesPerView: 2,
                    slidesOffsetBefore: 0
                },

                isEnd: false,
                isBeginning: true
            }
        },
        props: {
            dataSource: Object
        },
        methods: {
            slideNext() {
                this.swiper.slideNext()
            },

            slidePrev() {
                this.swiper.slidePrev()
            },

            slideChange() {
                this.isEnd = this.swiper.isEnd
                this.isBeginning = this.swiper.isBeginning
            },

            initSwiperArrow() {
                if (this.dataSource.items.length < 3) this.isEnd = true
                else  this.isEnd = false
            }
        },
       
        components: {
            NewsView,
            Swiper,
            SwiperSlide
        },
        computed: {
            swiper() {
                return this.$refs.swiper.swiperInstance;
            },
        },
        watch: {
            'dataSource.items' : function(val) {
                this.initSwiperArrow();
            }
        },
        mounted() {
            this.initSwiperArrow();
        }

    }

</script>