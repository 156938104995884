<template>
  
    <b-card bg-variant="transparent" no-body style="margin: 0px 16px">
        
        <b-card-body class="help-card">
            <div class="d-flex help-card__container">
                <div>
                    <b-card-title class="mb-1 help-card__title">
                        {{$t('dashboard.question-problem')}}
                    </b-card-title>
                    <b-card-text class="mb-1 help-card__text">
                        {{$t('dashboard.answer-problem')}}<br>
                        <a :href="faq_url">{{$t('dashboard.support-page')}}</a> 
                        {{$t('dashboard.or')}}  
                        <a style="color: #426FF6" @click="Tickets.compose.active = true">{{$t('dashboard.write-to-us')}}</a>
                    </b-card-text>
                </div>
                <img style="max-width: 105px; margin-left: 25px" src="@/assets/images/icons/send-message.png">
            </div>
        </b-card-body>
        
    </b-card>
</template>

<script>
import Tickets from "@/modules/tickets/"

export default {
    data() {
        return {
            Tickets,
        }
    },
    computed: {
        faq_url() {
            return `${process.env.VUE_APP_BASE_URL}knowledge/faq`
        }
    }
}
</script>

<style lang="scss" scoped>
    .help-card {
        padding: 20px; 
        background-color: #E4EAFD;
        border-radius: 16px;

        &__container {
            flex-direction: column; 
            align-items: center;
        }

        &__title {
            font-size: 20px; 
            font-weight: 600; 
            line-height: 28px;
        }

        &__text {
            font-size: 12px; 
            text-align: center
        }

    }

    .dark-layout  {
        .help-card {
            background-color: #262642 !important;
        }
    }
</style>