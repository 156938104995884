<template>
    <div>
        <contracts-view :summary='summary.cloud' :title="$t('cloud.cloud')" :action="$t('cloud.rent')" @action="cloudActionTap" @showAll="cloudShowAll" />
        <contracts-view :summary='summary.miners' :title="$t('miners.miners')" :action="$t('miners.buy-more')" @action="minersActionTap" @showAll="minersShowAll" />
        <individual-offers-view />
        <!-- <banner-offers-view-v2 /> -->
        <offers-view />
        <banner-offers-view />
        <special-offers-view />
        <news-view :dataSource="news.dataSource" />
        <help-card id="help" />

        <div @click="Tickets.compose.active = true" class="d-flex ticket-icon">
            <img alt="" src="@/assets/images/site/icons/ticket.png" class="" style="width: 20px"> 
        </div>

        <b-modal id="training-container" 
            modal-class="" 
            v-model="modalsVisibility.training" 
            centered 
            hide-footer
            hide-header
            no-close-on-backdrop
        >
            <training-content :stage="stageTraining" @changeStage="changeStageTraining" @scroll="trainingScroll" @show="showModal" @close="closeModal" />
        </b-modal>

        <b-modal id="first-offer" 
            modal-class="" 
            v-model="modalsVisibility.offer" 
            centered 
            hide-footer
            hide-header
            size="xl"
            no-close-on-backdrop
        >
            <offer-content @close="closeModal" />
        </b-modal>

        <b-modal id="share-modal" 
            modal-class="custom-contains" 
            v-model="modalsVisibility.share" 
            centered 
            hide-footer
            size="lg"
        >
            <share-modal-content @close="closeModal" />
        </b-modal>

        <b-modal id="welcome-modal" 
            modal-class="custom-contains" 
            v-model="modalsVisibility.welcome" 
            centered 
            hide-footer
        >
            <welcome-modal-content @close="closeModal" />
        </b-modal>

        <b-modal id="email-modal" 
            modal-class="custom-contains" 
            v-model="modalsVisibility.email" 
            centered 
            hide-footer
        >
            <email-modal-content @close="closeModal" />
        </b-modal>

    </div>
</template>

<script>
    import User from "@/modules/user"
    import Tickets from "@/modules/tickets"

    import news from "@/modules/contents/news"
    
    import ContractsView from "./widgets/contracts.desktop"
    import OffersView from "./widgets/offers.desktop"
    import NewsView from "./widgets/news.desktop"
    import HelpCard from "./widgets/help.desktop"

    import BannerOffersView from "./widgets/specialOffer/banner.desktop"
    import BannerOffersViewV2 from "./widgets/specialOffer/bannerV2"
    import SpecialOffersView from "./widgets/specialOffer/special.desktop"
    import IndividualOffersView from "./widgets/specialOffer/individual.desktop"

    import TrainingContent from "./training/training.desktop"
    import OfferContent from "./training/offer.desktop"
    
    import ShareModalContent from "./modals/share"
    import WelcomeModalContent from "./modals/welcome"
    import EmailModalContent from "./modals/email"

    export default {

        data() {
            return {
                news,
                Tickets,
                summary: {
                    cloud: {
                        total: 0,
                        current: 0,
                        ths: 0
                    },
                    miners: {
                        total: 0,
                        current: 0,
                        ths: 0
                    },    
                },
                stageTraining: 1,
                modalsVisibility: {
                    training: false,
                    offer: false,
                    share: false,
                    welcome: false,
                    email: false
                },

                query_test: new URLSearchParams(window.location.search)
            }
        },
        methods: {
            
            cloudActionTap( args ) {
                this.$router.push({ name: 'user-contracts-create' });
            },
            
            cloudShowAll( args ) {
                this.$router.push({ name: "user-contracts-list" });
            },
            
            minersActionTap( args ) {
                this.$router.push({ name: 'user-miners-catalog' });
            },
            
            minersShowAll( args) {
                this.$router.push({ name: "user-miners-list" });
            },

            closeModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
            },
            
            showModal( action ) {

                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },

            trainingScroll (n = null) {
                this.closeModal();

                const element = document.getElementById("help");

                const intersectionObserver = new IntersectionObserver((entries) => {
                let [entry] = entries;
                if (entry.isIntersecting) {
                    // stop observing
                    intersectionObserver.unobserve(help);

                    setTimeout(() => this.showModal('training'), 100)
                }
                });

                // start observing
                intersectionObserver.observe(help);

                element.scrollIntoView({
                    behavior: "smooth"
                });

            },

            welcomeHidden() {
                if (!this.$user.self.is_welcome) {
                    this.showModal('training');
                }
            },

            changeStageTraining(cb) {
                this.stageTraining++;

                if (this.stageTraining == 6 && cb) cb(); 
            },

        },
        components: {
            ContractsView,
            OffersView,
            NewsView,
            SpecialOffersView,
            BannerOffersView,
            IndividualOffersView,
            HelpCard,
            BannerOffersViewV2,
            TrainingContent,
            OfferContent,
            EmailModalContent,
            WelcomeModalContent,
            ShareModalContent
        },
        watch: {

        },
        mounted() {
            this.news.get();
            
            this.$request.get("contracts.summary").then( rsp => {
                this.summary.cloud = rsp.cloud;
                this.summary.miners = rsp.miners;
            });

            this.$root.$on('bv::modal::hidden', (modalId) => {
                if (modalId.componentId == 'welcome-modal') {
                    this.welcomeHidden();
                }
            })
            
            if (this.query_test.get('tests')) this.showModal(this.query_test.get('tests'))
        },
        created() {
            if (!User.self.is_verify && !localStorage.getItem('showingWelcome') && !localStorage.getItem('confirmed:email')) this.showModal('welcome');
            else if (localStorage.getItem('confirmed:email')) this.showModal('email');
            else if (!this.$user.self.is_welcome) this.showModal('training');
            else if (this.$user.self.is_feedback && !localStorage.getItem('showingShare')) this.showModal('share');
        }

    }

</script>

<style lang="scss">
    .ticket-icon {
        background: rgb(66, 111, 246);
        box-shadow: rgb(175 196 208 / 47%) 0px 10px 34px;
      
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;

        position: fixed;
        z-index: 100;
    }
</style>

<style lang="scss" scoped>
    .ticket-icon {
        width: 56px;
        height: 56px;

        bottom: 12px;
        right: 12px;
    }
</style>