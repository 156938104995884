<template>
    <div>
        <div style="margin-bottom: 32px;">
            <contracts-view :summary='summary.cloud' :title="$t('cloud.cloud')" :action="$t('cloud.rent')" @action="cloudActionTap" @showAll="cloudShowAll" />
        </div>
        
        <contracts-view :summary='summary.miners' :title="$t('miners.miners')" :action="$t('miners.buy-more')" @action="minersActionTap" @showAll="minersShowAll" />

        <div style='padding: 0 16px;'>
            <b-button v-if="special.banners.length > 0" :href="top_errors_pdf" target="_blank" variant="outline-primary" block style="height: 44px; margin: 2rem 0; font-size: 14px; border-radius: 10px; padding-top: 14px; border-width: 2px;">{{$t('pick-up-bonus')}}</b-button>
        </div>
        
        <individual-offers-view />
        <!-- <banner-offers-view-v2 /> -->
        <offers-view />
        <banner-offers-view />
        <special-offers-view />
        
        <!--<refferal-view />-->
        
        <news-view :dataSource="news.dataSource" />
        <help-card />

        <div @click="Tickets.compose.active = true" class="d-flex ticket-icon">
            <img alt="" src="@/assets/images/site/icons/ticket.png" class="" style="width: 20px"> 
        </div>
        
        <b-modal id="training-container-mobile" 
            v-model="modalsVisibility.training" 
            centered 
            hide-footer
            size="lg"
            no-close-on-backdrop
        >
            <training-content @show="showModal" @close="closeModal" />
        </b-modal>

        <b-modal id="first-offer-mobile" 
            modal-class="" 
            v-model="modalsVisibility.offer" 
            centered 
            hide-footer
            hide-header
            size="xl"
           no-close-on-backdrop
        >
            <offer-content @close="closeModal" />
        </b-modal>

          <b-modal id="share-modal" 
            modal-class="custom-contains" 
            v-model="modalsVisibility.share" 
            centered 
            hide-footer
        >
            <share-modal-content @close="closeModal" />
        </b-modal>

        <b-modal id="welcome-modal" 
            modal-class="custom-contains" 
            v-model="modalsVisibility.welcome" 
            centered 
            hide-footer
            @hidden="welcomeHidden"
        >
            <welcome-modal-content @close="closeModal" />
        </b-modal>

        <b-modal id="email-modal" 
            modal-class="custom-contains" 
            v-model="modalsVisibility.email" 
            centered 
            hide-footer
        >
            <email-modal-content @close="closeModal" />
        </b-modal>

    </div>
</template>

<script>
    import User from "@/modules/user"
    import Tickets from "@/modules/tickets"

    import news from "@/modules/contents/news"
    import special from "@/modules/contents/special"

    import ContractsView from "./widgets/contracts.mobile"
    import OffersView from "./widgets/offers.mobile"
    import NewsView from "./widgets/news.mobile"
    import HelpCard from "./widgets/help.mobile"

    import BannerOffersView from "./widgets/specialOffer/banner.mobile"
    import BannerOffersViewV2 from "./widgets/specialOffer/bannerV2"
    import SpecialOffersView from "./widgets/specialOffer/special.mobile"
    import IndividualOffersView from "./widgets/specialOffer/individual.mobile"
    
    import RefferalView from "@/components/RefferalView" 

    import TrainingContent from "./training/training.mobile"
    import OfferContent from "./training/offer.mobile"

    import ShareModalContent from "./modals/share"
    import WelcomeModalContent from "./modals/welcome"
    import EmailModalContent from "./modals/email"

    export default {

        data() {
            return {
                special,
                news,
                Tickets,
                summary: {
                    cloud: {
                        total: 0,
                        current: 0,
                        ths: 0
                    },
                    miners: {
                        total: 0,
                        current: 0,
                        ths: 0
                    }
                },
                modalsVisibility: {
                    training: false,
                    offer: false,
                    share: false,
                    welcome: false,
                    email: false
                },

                query_test: new URLSearchParams(window.location.search)
            }
        },
        methods: {
            
            cloudActionTap( args ) {
                this.$router.push({ name: 'user-contracts-create' });
            },
            
            cloudShowAll( args ) {
                this.$router.push({ name: "user-contracts-list" });
            },
            
            minersActionTap( args ) {
                this.$router.push({ name: 'user-miners-catalog' });
            },
            
            minersShowAll( args) {
                this.$router.push({ name: "user-miners-list" });
            },

            closeModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
            },
            
            showModal( action ) {

                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },

            welcomeHidden() {
                if (!this.$user.self.is_welcome) {
                    this.showModal('training');
                }
            }
            
        },
        computed: {
            top_errors_pdf() {
                return `${process.env.VUE_APP_BASE_URL}toperrors/pdf`;
            }
        },
        components: {
            ContractsView,
            OffersView,
            NewsView,
            RefferalView,
            SpecialOffersView,
            BannerOffersView,
            IndividualOffersView,
            BannerOffersViewV2,
            HelpCard,
            TrainingContent,
            OfferContent,
            EmailModalContent,
            WelcomeModalContent,
            ShareModalContent
        },
        watch: {

        },
        mounted() {
            
            this.$request.get("contracts.summary").then( rsp => {
                this.summary.cloud = rsp.cloud;
                this.summary.miners = rsp.miners;
            });
            
            this.news.get();
            
            if (this.query_test.get('tests')) this.showModal(this.query_test.get('tests'))
        },
        created() {
            if (!User.self.is_verify && !localStorage.getItem('showingWelcome') && !localStorage.getItem('confirmed:email')) this.showModal('welcome');
            else if (localStorage.getItem('confirmed:email')) this.showModal('email');
            else if (!this.$user.self.is_welcome) this.showModal('training');
            else if (this.$user.self.is_feedback && !localStorage.getItem('showingShare')) this.showModal('share');
        }

    }

</script>

<style lang="scss" scoped>
    .ticket-icon {
        width: 48px;
        height: 48px;

        bottom: 77px;
        right: 12px;
    }
</style>