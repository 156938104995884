<template>
    <div class="data-form">

        <center>
            <div class="modal-title mb-2" style="padding: 32px; border-radius: 100px; background-color: #ffcc4233; width: 112px">
                <b-img
                src="~@/assets/images/icons/emoji/handx4.png"
                fluid
                alt="hand"
                style="width: 48px; height: 48px;"
            ></b-img>
            </div>

            <i18n path="modals.welcome.header" tag="p" style="font-size: 22px; font-weight: 500; line-height: 24px">
                <br>
            </i18n>

            <i18n path="modals.welcome.text" tag="p" style="font-size: 14px;">
                <br>
                <b-link :href="`mailto:${$user.self.email}`" class="text-ellipsis ">{{$user.self.email}}</b-link>
            </i18n>
        </center>
      
    </div>
</template>

<script>
export default {
    data() {
        return {
            is_loading: false,
        }
    },
    mounted() {
        localStorage.setItem('showingWelcome', true);
    }
}
</script>
