<template>
  
    <b-card bg-variant="transparent" no-body >
        
        <b-card-body class="help-card">
            <div class="d-flex" style="justify-content: space-between">
                <div>
                    <b-card-title class="mb-1 help-card__title">
                        {{$t('dashboard.question-problem')}}
                    </b-card-title>
                    <b-card-text style="font-size: 12px">
                        {{$t('dashboard.answer-problem')}}
                        <a :href="faq_url">{{$t('dashboard.support-page')}} </a> 
                        {{$t('dashboard.or')}} 
                        <a style="color: #426FF6" @click="Tickets.compose.active = true">{{$t('dashboard.write-to-us')}}</a>
                    </b-card-text>
                </div>
                <div>
                    <img style="max-width: 105px" src="@/assets/images/icons/send-message.png">
                </div>
            </div>
        </b-card-body>
        
    </b-card>
</template>

<script>
import Tickets from "@/modules/tickets/"

export default {
    data() {
        return {
            Tickets,
        }
    },
    computed: {
        faq_url() {
            return `${process.env.VUE_APP_BASE_URL}knowledge/faq`
        }
    }
}
</script>

<style lang="scss" scoped>
    .help-card {
        background-color: #E4EAFD;
        border-radius: 16px;
        padding: 28px 50px 13px 32px;

        &__title {
            font-size: 20px; 
            font-weight: 600; 
            line-height: 28px;
        }
    }

    .dark-layout  {
        .help-card {
            background-color: #262642 !important;
        }
    }
</style>