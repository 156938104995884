<template>
    <b-card @click="item.views = item.views + 1; $router.push({ name: 'user-news-view', params:{ slug: item.slug }})" no-body style=" margin: 0 12px; overflow: hidden; cursor: pointer;" class="shadow mb-2">
        <div class="attachment"
            :style="{
                margin: '0px',
                width: '100%',
                height: large ? '225px' : '155px',
                '--thumb': `url(${item.media.urls.thumb})`,
                '--placeholder': `url(${item.media.urls.placeholder})`,
            }"
        >

        </div>
        <b-card-body  style='padding: 12px 16px 16px 16px;'>
            <b-card-text class="font-weight-bolder" style="display: -webkit-box; -webkit-line-clamp: 2;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
                {{ $i18n.locale == 'ru' ? item.header : item.header_en || item.header }}
            </b-card-text>
            
           
            <b-card-text style="display: -webkit-box; -webkit-line-clamp: 3;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
                <span v-html="$i18n.locale == 'ru' ? item.description : item.description_en || item.description" />
            </b-card-text>
            
            <b-card-text>
                <div class='d-flex'>
                    <b-badge v-for="(category, index) in item.categories" :key="index" class='mr-1'>
                        {{ $i18n.locale == 'ru' ? category.title : category.title_en || category.title }}
                    </b-badge>
                </div>
            </b-card-text>
            
            <b-card-text class="text-muted d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    <feather-icon icon="EyeIcon" size="13" />
                    <span style="padding-left: 5px; font-size: 13px;">{{ item.views }}</span>
                </div>
                <div>
                     {{ utils.lastActivityFormat(item.timestamp) }}
                </div>
                
            </b-card-text>
        </b-card-body>
    </b-card>
</template>

<script>

    import utils from "@/modules/utils/"

    export default {

        data() {
            return {
                utils
            }
        },
        props: {
            item: Object,
            large: {
                type: Boolean,
                default: false
            }
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>