<template>
    <div>
        <showAt breakpoint="large">
            <div style="position: relative; overflow: hidden; width: 100%; border-radius: 16px; padding: 16px 16px 175px 16px; background: linear-gradient(249.98deg, #BD80F4 -47.44%, #426FF6 149.19%);">
                <b-card-text style="font-size: 20px; font-weight: 500; line-height: 28px; color: #FFF;">
                    Реферальная программа
                </b-card-text>
                <b-card-text style="color: #FFF;">
                    Постройте свою партнерскую сеть и зарабатывайте с каждого приведенного клиента по реферальной ссылке
                </b-card-text>
                <b-button variant="white" style="background-color: transparent; color: #fff; border: 2px solid #FFF; border-radius: 10px;">
                    Узнать подробнее
                </b-button>
                
                <div class="referral-card-image" style="position: absolute; left: 0px; height: 175px; width: 100%; background-size: cover; background-position: bottom;">
                    
                </div>
                
            </div>
        </showAt> 
        
        
        <showAt breakpoint="medium">
            <div style="position: relative; overflow: hidden; width: 100%; border-radius: 16px; padding: 16px 50% 16px 16px; background: linear-gradient(249.98deg, #BD80F4 -47.44%, #426FF6 149.19%);">
                <b-card-text style="font-size: 20px; font-weight: 500; line-height: 28px; color: #FFF;">
                    Реферальная программа
                </b-card-text>
                <b-card-text style="color: #FFF;">
                    Постройте свою партнерскую сеть и зарабатывайте с каждого приведенного клиента по реферальной ссылке
                </b-card-text>
                <b-button variant="white" style="background-color: transparent; color: #fff; border: 2px solid #FFF; border-radius: 10px;">
                    Узнать подробнее
                </b-button>
                
                <div class="referral-card-image" style="position: absolute; right: 0; top: 0; width: 50%; height: 100%; background-size: cover; background-position: bottom;">
                    
                </div>
                
            </div>
        </showAt> 
        
        <showAt breakpoint="small">
            <div style="position: relative; overflow: hidden; border-radius: 16px; padding: 16px 16px 185px 16px; margin: 0 16px; background: linear-gradient(249.98deg, #BD80F4 -47.44%, #426FF6 149.19%);">
                <b-card-text style="font-size: 20px; font-weight: 500; line-height: 28px; color: #FFF;">
                    Реферальная программа
                </b-card-text>
                <b-card-text style="color: #FFF;">
                    Постройте свою партнерскую сеть и зарабатывайте с каждого приведенного клиента по реферальной ссылке
                </b-card-text>
                <b-button variant="white" style="background-color: transparent; color: #fff; border: 2px solid #FFF; border-radius: 10px;">
                    Узнать подробнее
                </b-button>
                
                <div class="referral-card-image" style="position: absolute; top:0; left: 0; right: 0; bottom: 0px; background-size: contain; background-position: bottom;">
                    
                </div>
                
            </div>
        </showAt>
    </div>
</template>

<script>

    export default {

        data() {
            return {}
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>