<template>
    <b-card no-body class="card-special" style="margin: 0 12px;" :style="!item.special && isPreview ? 'height: 300px; margin-bottom: 30px' : ''">
        <b-card-body v-if="item && item.type === 3" style="padding: 16px 24px;">
                  
                <countdown :end-time="item.until * 1000" ref="countdown" @finish="finish()">
                    
                    <template
                        v-slot:process="countdown">
                        <div class="d-flex special-title" style="align-items: flex-start; justify-content: space-between;">
                            
                            <div class="d-flex" style="align-items: center; margin-right: 10px;">
                                <b-img
                                    src="~@/assets/images/icons/emoji/fire.png"
                                    fluid
                                    alt="bonus"
                                    style="width: 26px; height: 24px; margin-right: 10px"
                                ></b-img>
                                <span class="special-title-item" style="font-weight: 600">{{ $i18n.locale == 'ru' ? item.title : item.title_en || item.title }} </span>
                            </div>

                            <div class="d-flex" style="">  
                                <div class="d-flex" style="flex-direction: column; align-items: center;">  
                                    <b-img
                                        src="~@/assets/images/vectors/vector.png"
                                        fluid
                                        alt="vector"
                                        style="width: 60px; height: 45px;"
                                    ></b-img>
                                    <span style="font-size: 10px">
                                        {{$t('banners.burnt-down')}}
                                    </span>
                                </div>

                                <div class="d-flex special-countdown" style="align-items: flex-start;">  
                                    <div class="d-flex counter-offer">
                                        <div>{{ countdown.timeObj.h }}</div>
                                            <span>:</span>
                                        <div>{{ countdown.timeObj.m }}</div>
                                            <span>:</span>
                                        <div>{{ countdown.timeObj.s }}</div>
                                    </div>
                                    <div style="font-size: 12px; font-weight: 400; margin-left: 6px; line-height: 1.2; opacity: 0.5">
                                        {{$t('banners.offer')}}<br>{{$t('banners.valid')}} 
                                    </div>
                                </div>
                            </div>

                        </div>

                    </template>
                </countdown>
         

            <div class="d-flex special-content" style="align-items: flex-start; font-size: 20px; flex-wrap: wrap;">
                <div class="d-flex" style="flex-direction: column; margin-top: 15px;">
                    <div class="d-flex" style="flex-wrap: wrap;">
                        <span class=" font-weight-light" style="margin-right: 15px">
                              {{$t('banners.get')}} 
                        </span>
                        <b-badge variant="success" style="background-color: #3ED085; color: #FFF; font-size: 20px; margin-right: 15px; font-weight: 400;">{{ $i18n.locale == 'ru' ? item.caption : item.caption_en || item.caption }}</b-badge>
                        <span class=" font-weight-light" style="">
                              {{$t('banners.free')}} 
                        </span>
                    </div>
                </div>
                
                <div class="d-flex first-special" style="flex-direction: column; flex: 1">
                    <div class="d-flex" style="line-height: 1;" > 
                        
                        <div>
                            <div class=" font-weight-light special-check-list" :class="item.state == 2 ? 'disabled' : ''" style="">
                                <span> + {{$t('banners.check-list')}}</span> <br>
                                <i18n path="banners.how-make-money-on-mining-2022" tag="span" style="font-size: 14px;">
                                    <br>
                                </i18n>
                            </div>

                            <div v-if="item.state == 2" class="d-flex time-up-block" style="align-items: center;">
                                <span class="font-weight-light" style="font-size: 12px">
                                    {{$t('banners.bonus-gone')}}
                                </span>
                                <b-img
                                    src="~@/assets/images/icons/emoji/pensive-face.png"
                                    fluid
                                    alt="checklist"
                                    style="width: 20px; height: 20px; margin-left: 5px"
                                ></b-img>
                            </div>
                        </div>

                       

                        <b-img
                            src="~@/assets/images/icons/checklist_x2.png"
                            fluid
                            alt="checklist"
                            style="width: 110px; height: 110px;"
                            :class="item.state == 2 ? 'disabled' : ''"
                        ></b-img>
                    </div>
                  
                </div>
            </div>

        </b-card-body>

        <b-card-body v-else style="padding: 16px;">

            <countdown :end-time="new Date().getTime() + item.until * 3600000" ref="countdown">
                
                <template
                    v-slot:process="countdown">
                    <div class="d-flex mb-1 special-title" style="align-items: flex-start; justify-content: space-between; flex-direction: column;">
                        
                        <div class="d-flex" style="align-items: center; margin-right: 10px;">
                            <b-img
                                src="~@/assets/images/icons/emoji/fire.png"
                                fluid
                                alt="bonus"
                                style="width: 26px; height: 24px; margin-right: 10px"
                            ></b-img>
                            <span class="special-title-item" style="font-weight: 600">{{ $i18n.locale == 'ru' ? item.title : item.title_en || item.title }} </span>
                        </div>

                        <div class="d-flex" style="">  
                            <div class="d-flex" style="flex-direction: column; align-items: center;">  
                                <b-img
                                    src="~@/assets/images/vectors/vector.png"
                                    fluid
                                    alt="vector"
                                    style="width: 60px; height: 45px;"
                                ></b-img>
                                <span style="font-size: 10px">
                                    {{$t('banners.burnt-down')}}
                                </span>
                            </div>

                            <div class="d-flex special-countdown" style="align-items: flex-start;">  
                                <div class="d-flex counter-offer">
                                    <div>{{ countdown.timeObj.h }}</div>
                                        <span>:</span>
                                    <div>{{ countdown.timeObj.m }}</div>
                                        <span>:</span>
                                    <div>{{ countdown.timeObj.s }}</div>
                                </div>
                                <div style="font-size: 12px; font-weight: 400; margin-left: 6px; line-height: 1.2; opacity: 0.5">
                                    {{$t('banners.offer')}}<br>{{$t('banners.valid')}} 
                                </div>
                            </div>
                        </div>

                    </div>

                </template>
        
            </countdown>

            <div class="d-flex special-content" style="align-items: flex-start; font-size: 20px; flex-wrap: wrap;">
                <div class="d-flex" style="flex-direction: column; margin-top: 15px;">
                    <div class="d-flex" style="flex-wrap: wrap;">
                        <span class=" font-weight-light" style="margin-right: 15px">
                            {{$t('banners.get')}}  
                        </span>
                        <b-badge variant="success" style="background-color: #3ED085; color: #FFF; font-size: 20px; margin-right: 15px; font-weight: 400;">{{ $i18n.locale == 'ru' ? item.caption : item.caption_en || item.caption }}</b-badge>
                        <span class=" font-weight-light" style="">
                            {{$t('banners.free')}}  
                        </span>
                    </div>
                </div>
                
                <div class="d-flex first-special" style="flex-direction: column; flex: 1">
                    <div class="d-flex" style="line-height: 1;" > 
                        
                        <div>
                            <div class=" font-weight-light special-check-list" :class="item.state == 2 ? 'disabled' : ''" style="">
                                <span> + {{$t('banners.check-list')}}</span> <br>
                                <i18n path="banners.how-make-money-on-mining-2022" tag="span" style="font-size: 14px;">
                                    <br>
                                </i18n>
                            </div>

                            <div v-if="item.state == 2" class="d-flex time-up-block" style="align-items: center;">
                                <span class="font-weight-light" style="font-size: 12px">
                                    {{$t('banners.bonus-gone')}}
                                </span>
                                <b-img
                                    src="~@/assets/images/icons/emoji/pensive-face.png"
                                    fluid
                                    alt="checklist"
                                    style="width: 20px; height: 20px; margin-left: 5px"
                                ></b-img>
                            </div>
                        </div>

                       

                        <b-img
                            src="~@/assets/images/icons/checklist_x2.png"
                            fluid
                            alt="checklist"
                            style="width: 90px; height: 90px;"
                            :class="item.state == 2 ? 'disabled' : ''"
                        ></b-img>
                    </div>
                  
                </div>
            </div>

        </b-card-body>
    </b-card>
</template>

<script>

    import special from "@/modules/contents/special"

    export default {

        data() {
            return {
                special,
            }
        },
        props: {
            item: Object,
            isPreview: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            finish() {
                this.$emit('finishSpecial');
            },
            mediaViewStyle() {
                return {
                    height: '120px',
                    width: '100%',
                    minWidth: '100%',
                    backgroundImage: `url(${this.item.miner.medias[0].urls.thumb})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: "white",
                    borderRadius: "12px"
                }
            },
            order(  ) {
                
                if( this.isPreview ) return;
                
                this.$router.push({
                    name: "user-contracts-create",
                    query: {
                        'with-offer': this.item.special.offer.id
                    }
                });
            },
        },
        components: {

        },
        computed: {
            profit_btc() {
                 return (((84600 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.item.ths )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) );
            },
            
            profit() {
                return this.profit_btc * this.$blockchain.tickers.values.USD.last;
            },
            
        },
        watch: {
            item:{
                deep: true,
                handler(bef) {   
                    setTimeout(() => {
                    this.$refs.countdown.startCountdown(true)
                    }, 500 );
                }
            }
        },
        mounted() {
        }

    }

</script>

<style lang="scss" scoped>
    
    .card-special {
        .counter-offer {
            align-items: center;
        }
        
        .counter-offer div {
            margin: 0 2px;
            color:rgba(66, 111, 246, 1) !important;
            background-color: #FFF !important;
            width: 27px; 
            height: 27px;
            text-align: center;
            line-height: 27px;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 500;
           
        }
    
        .counter-danger div {
            background-color: #878787 !important;
        }
    }

    .things > .content {
        float: left;
        width: 50%;
        /* height: 500px; */
        -webkit-box-sizing: border-box; 
        -moz-box-sizing: border-box;    
        box-sizing: border-box;
        position: relative;
    }

    /* Arrow */

    .arrow {
    position: relative;
    margin: 0 auto;
    width: 100px;
    }

    .arrow .curve {
    border: 2px solid #9CA2B1;
    border-color: transparent transparent transparent #9CA2B1;
    height: 75px;
    width: 100px;
    border-radius: 230px 0 0 150px;
    }

    .arrow .point {
    position: absolute;
    left: 10px;
    top: 66px;
    }

    .arrow .point:before, .arrow .point:after {
    /* border: 1px solid #9CA2B1 ; */
    background: #9CA2B1;
    width: 2px;
    height: 10px;
    content: "";
    position: absolute;
    }

    .arrow .point:before {
    top: -6px;
    left: -7px;
    transform:rotate(-74deg);
    -webkit-transform:rotate(-74deg);
    -moz-transform:rotate(-74deg);
    -ms-transform: rotate(-74deg);
    }

    .arrow .point:after {
    top: -9px;
    left: -1px;
    transform:rotate(12deg);
    -webkit-transform: rotate(12deg);
    -moz-transform:rotate(12deg);
    -ms-transform: rotate(12deg);
    }

    .disabled {
        opacity: 0.4
    }

</style>