<template>
    <b-card class="client-first-offer" style="margin-bottom: 0px">
        <center>
            <b-card-title style="font-size: 28px; line-height: 32px;">
                <i18n path="modals.training.offer-header" tag="span">
                    <br>
                </i18n>
            </b-card-title>

            <b-card-text style="font-size: 14px; line-height: 20px;">
                <i18n path="modals.training.offer-text" tag="span">
                    <br>
                </i18n>
            </b-card-text>

            <b-card-title class="d-flex align-items-center mb-0" style="justify-content: center;">
                    
                <div style="margin-right: 8px;">{{$t('offer-card.text-timer')}}</div>
                                
                <countdown :end-time="new Date(offers.until * 1000).getTime()">
                    
                    <template
                        v-slot:process="countdown">
                        <div class="d-flex counter">
                            <div>{{ countdown.timeObj.h }}</div>
                                <span class="text-muted">:</span>
                            <div>{{ countdown.timeObj.m }}</div>
                                <span class="text-muted">:</span>
                            <div>{{ countdown.timeObj.s }}</div>
                        </div>
                    </template>
            
                </countdown>
                
                <div class="text-muted" style="font-size: 12px; font-weight: 400; margin-left: 6px;">
                    {{$t('offer-card.offer')}}<br>{{$t('offer-card.valid')}} 
                </div>
                    
            </b-card-title>
        </center>


         <b-card-body style="padding: 0; position: relative;">
            <b-button @click="slidePrev()" :class="isBeginning ? 'hidden' : ''" variant="light" style="position: absolute; width: 35px; height: 35px; border-radius: 55px; padding: 0; top: calc(50% - 17px); left: 0; z-index: 99;">
                <feather-icon icon="ChevronLeftIcon" size="17" />
            </b-button>
                <swiper ref="swiper" :options="options" @slideChange="slideChange">
                    <swiper-slide v-for="(item, index) in offers.items" :key="index" style="padding: 20px">
                        <offer-card :item="item" />
                    </swiper-slide>
                </swiper>
            <b-button @click="slideNext()" :class="isEnd ? 'hidden' : ''" variant="light" style="position: absolute; width: 35px; height: 35px; border-radius: 55px; padding: 0; top: calc(50% - 17px); right: 0; z-index: 99;">
                <feather-icon icon="ChevronRightIcon" size="17" />
            </b-button>
        </b-card-body>

        <div style="width: 100%; padding: 0px 20px">
            <b-button @click="close" pill variant="light" style="line-height: 1; width: 100%; padding-top: 18px; padding-bottom: 18px; font-weight: 600;">
                {{$t('modals.training.offer-btn')}}
            </b-button>
        </div>

    </b-card>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import offers from "@/modules/contents/offers"
import OfferCard from "@/components/OfferCard"

export default {
  data() {
    return {
        offers,
        options: {
            slidesPerView: 3,
            slidesOffsetBefore: 0,
            observer: true,
            observeParents: true
        },

        isEnd: false,
        isBeginning: true
    }
  },
  methods: {
    show() {
      this.$emit('show', '');
    },
    close() {
      this.$emit('close');
    },
    order( item ) {
        this.$router.push({
            name: "user-contracts-create",
            query: {
                'with-offer': item.id
            }
        });
    },

    slideNext() {
        this.swiper.slideNext()
    },

    slidePrev() {
        this.swiper.slidePrev()
    },

    slideChange() {
        this.isEnd = this.swiper.isEnd
        this.isBeginning = this.swiper.isBeginning
    },

    initSwiperArrow() {
        if (this.offers.items.length < 4) this.isEnd = true
        else  this.isEnd = false
    }
  },
    components: {
        Swiper,
        SwiperSlide,
        OfferCard
    },
    computed: {
        swiper() {
            return this.$refs.swiper.swiperInstance;
        }
    },
    watch: {
        'offers.items' : function(val) {
            this.initSwiperArrow()
        }
    },
    mounted() {
        this.initSwiperArrow();
    }

}
</script>

<style lang="scss" scoped>
    .client-first-offer {
        .card {
            border: 1px solid #EBECF2;
            box-sizing: border-box;
            margin: 0px !important;
        }
        .card:hover {
            box-shadow: 0px 6px 40px rgba(99, 109, 137, 0.15) !important;
            border: none;
        }
    }

</style>