<template>

    <b-card v-if="special.items.length > 0" bg-variant="transparent" no-body>
        
        <b-card-header>
            <b-card-title class="d-flex flex-column">
                
                <div style="margin-right: 8px; margin-bottom: 8px;">{{$t('offer-card.webinar-participants')}}</div>
                
            </b-card-title>
        </b-card-header>
        
        
          <b-card-body style="padding: 0">
            <swiper :options="swiperOptions">
                <swiper-slide v-for="(item, index) in special.items" :key="index">
                    <offer-card :item="item" @finishSpecial="finishSpecial"/>
                </swiper-slide>
            </swiper>
        </b-card-body>
        
    </b-card>
    
</template>

<style>
    
    .counter {
        align-items: center;
    }
    
    .counter div {
        margin: 0 2px;
        color: #FFF;
        background-color: var(--theme-color);
        width: 27px; 
        height: 27px;
        text-align: center;
        line-height: 27px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
       
    }
</style>

<script>
    
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import special from "@/modules/contents/special"
    import OfferCard from "@/components/OfferCard"
    
    export default {

        data() {
            return {
                special,
                swiperOptions: {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 0,
                    slidesOffsetBefore: 0
                },
            }
        },
        methods: {
            order( item ) {
                this.$router.push({
                    name: "user-contracts-create",
                    query: {
                        'with-offer': item.id
                    }
                });
            },

            finishSpecial() {
                this.special.get(true);
            }
        },
        components: {
            Swiper,
            SwiperSlide,
            OfferCard
        },
        computed: {
            // swiper() {
            //     return this.$refs.swiper.swiperInstance;
            // }
        },
        watch: {

        },
        mounted() {
           
        }

    }

</script>