<template>
  <b-overlay
        :show="is_loading"
        blur="50"
        rounded="lg"
        spinner-lg
        spinner-variant="primary"
        variant="white"
    > 
        <transition name="zoom-fade" mode="out-in">

            <div v-if="resp">
                <div class="d-flex mb-2">
                    <img alt="" class="mx-auto" src="@/assets/images/site/icons/success.svg">
                </div>
                <b-row>
                    <b-col lg="12" md="12">
                        <q-header>
                            <i18n path="modals.share.resp-header" tag="b">
                                <br>
                            </i18n>
                        </q-header>
                  
                        <b-button variant="light" block style="border-radius: 16px; margin-top: 22px; background-color: white;  border: 1px solid var(--theme-accent-light); border-radius: 100px; color: var(--theme-accent-light); font-weight: 600; font-size: 18px; padding: 20px 0px" @click="$emit('close')">
                            {{$t('modals.share.btns.go-site')}}
                        </b-button>
                    </b-col>
                </b-row>
            </div>

            <div v-else class="data-form">

                <div class="modal-title mb-2" style="line-height: 24px;">
                    {{$t('modals.share.que-raiting')}}
                </div>

                <showAt breakpoint="mediumAndAbove">
                    <div class="mb-2">
                        <div class="d-flex mb-1" style="flex-direction: row; justify-content: space-between;">
                            <div v-for="(grade, index) in grades" :key="index" class="item-grade" :class="selected == grade ? 'is-active' : ''" @click="select(grade)">
                                {{grade}}
                            </div>
                        </div>
                        <div class="d-flex" style="justify-content: space-between;">
                            <span class="text-muted" style="font-size: 12px"> {{$t('modals.share.not-advise')}} </span>
                            <span class="text-muted" style="font-size: 12px"> {{$t('modals.share.advise')}} </span>
                        </div>
                    </div>
                </showAt>
                <showAt breakpoint="small">
                    <div class="mb-2 mt-1">
                        <span class="text-muted" style="font-size: 12px"> {{$t('modals.share.not-advise')}} </span>
                        <div class="d-flex mb-1 mt-1" style="flex-direction: row; justify-content: space-between;">
                            <div class="d-flex" style="flex-direction: column; width: 100%">
                            
                            <div class="d-flex mb-1" style="justify-content: space-around;">
                                <div v-for="(grade, index) in gradesFirst" :key="index" class="item-grade" :class="selected == grade ? 'is-active' : ''" @click="select(grade)">
                                    {{grade}}
                                </div>
                            </div>
                            <div class="d-flex" style="justify-content: space-around;">
                                <div v-for="(grade, index) in gradesLast" :key="index" class="item-grade" :class="selected == grade ? 'is-active' : ''" @click="select(grade)">
                                    {{grade}}
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="d-flex" style="justify-content: flex-end;">
                            <span class="text-muted" style="font-size: 12px"> {{$t('modals.share.advise')}} </span>
                        </div>
                    </div>
                </showAt>
                <span style="font-size: 22px; font-weight: 500">{{$t('modals.share.que')}}</span>
                <b-form-group class="mt-1">
                    <b-form-textarea rows="3" v-model="text" :placeholder="$t('modals.share.placeholder')" style="height: auto;" />
                </b-form-group>
              
                <b-button @click="send" :disabled="!isCanSend" variant="primary" block size="lg" style="border-radius: 16px; margin-top: 22px;">

                    <div v-if="is_loading" class="d-flex justify-content-center">
                        <span class="mt-auto mb-auto ml-1">{{$t('modals.share.btns.sending')}}</span>
                    </div>
                    <span v-else>
                        {{$t('modals.share.btns.send')}}
                    </span>

                </b-button>

            </div>
        </transition>
    </b-overlay>
</template>

<script>
export default {

    data() {
        return {
            is_loading: false,
            resp: false,

            text: null,

            grades: [1,2,3,4,5,6,7,8,9,10],
            gradesFirst: [1,2,3,4,5],
            gradesLast: [6,7,8,9,10],

            selected: null
        }
    },

    methods: {
        send() {

            this.$request.post("user.feedback", {
                rating: this.selected,
                text: this.text
            }).then( rsp => {
                this.resp = true;
            });
        },
        select(grade) {
            this.selected = grade;
        }
    },

    computed: {
        isCanSend() {
            if (!this.selected) return false;

            return true;
        }
    },

    mounted() {
        localStorage.setItem('showingShare', true);
    }

}
</script>

<style lang="scss" scoped>
    .item-grade {
        width: 38px;
        height: 38px;
        border: 1px solid #EBECF2;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .item-grade.is-active {
        background-color: #426FF6;
        border-color: #426FF6;
        color: #FFF;
    }
</style>
