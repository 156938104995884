<template>

    <b-card v-if="special.individual_items.length > 0" bg-variant="transparent" no-body>
        
        <b-card-header>
            <b-card-title class="d-flex flex-column">
                
                <div style="margin-right: 8px; margin-bottom: 8px;">{{$t('offer-card.offers-for-you')}}</div>
                                
                <div class="d-flex">

                    <countdown :end-time="special.individual_until * 1000" ref="countdown" @finish="finish()">
                    
                    <template
                      v-slot:process="countdown">
                        <div class="d-flex counter">
                            <div>{{ countdown.timeObj.h }}</div>
                                <span class="text-muted">:</span>
                            <div>{{ countdown.timeObj.m }}</div>
                                <span class="text-muted">:</span>
                            <div>{{ countdown.timeObj.s }}</div>
                        </div>
                    </template>
            
                    </countdown>
                    
                    <div class="text-muted" style="font-size: 12px; font-weight: 400; margin-left: 6px;">
                        {{$t('offer-card.offer')}}<br>{{$t('offer-card.valid')}} 
                    </div>
                </div>
            </b-card-title>
        </b-card-header>
        
        
        <b-card-body style="padding: 0; position: relative;">
            <swiper ref="swiper" :options="swiperOptions">
                <swiper-slide v-for="(item, index) in special.individual_items" :key="index">
                    <offer-card :item="item" />
                </swiper-slide>
            </swiper>
        </b-card-body>
        
    </b-card>
    
</template>

<style>
    
    .counter {
        align-items: center;
    }
    
    .counter div {
        margin: 0 2px;
        color: #FFF;
        background-color: var(--theme-color);
        width: 27px; 
        height: 27px;
        text-align: center;
        line-height: 27px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
       
    }
</style>

<script>
    
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import special from "@/modules/contents/special"
    import OfferCard from "@/components/OfferCard"
    
    export default {

        data() {
            return {
                special,
                swiperOptions: {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 0,
                    slidesOffsetBefore: 0
                },
            }
        },
        methods: {
            order( item ) {
                this.$router.push({
                    name: "user-contracts-create",
                    query: {
                        'with-offer': item.id
                    }
                });
            },

            finish() {
                this.special.get(true);
            },

        },
        components: {
            Swiper,
            SwiperSlide,
            OfferCard
        },
        computed: {
            // swiper() {
            //     return this.$refs.swiper.swiperInstance;
            // }
        },
        watch: {

        },
        mounted() {
        }

    }

</script>