<template>

    <b-card v-if="special.banners.length > 0" bg-variant="transparent" no-body>
        
        <!-- <b-card-header>
            <b-card-title class="d-flex align-items-center">
                
            </b-card-title>
        </b-card-header> -->
        
        
        <b-card-body style="padding: 0; position: relative;">
            <swiper ref="swiper" :options="swiperOptions" >
                <swiper-slide v-for="item in special.banners">
                    <offer-card :item="item" @finishSpecial="finishSpecial"/>
                </swiper-slide>
            </swiper>
            <!-- <b-button @click="swiper.slideNext()" variant="light" style="position: absolute; width: 35px; height: 35px; border-radius: 55px; padding: 0; top: calc(50% - 17px); right: 0; z-index: 99;">
                <feather-icon icon="ChevronRightIcon" size="17" />
            </b-button> -->
        </b-card-body>
        
    </b-card>
    
</template>

<style>
    
    .counter {
        align-items: center;
    }
    
    .counter div {
        margin: 0 2px;
        color: #FFF;
        background-color: var(--theme-color);
        width: 27px; 
        height: 27px;
        text-align: center;
        line-height: 27px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
       
    }
</style>

<script>
    
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import special from "@/modules/contents/special"
    import OfferCard from "@/components/SpecialOfferBanner"
    
    export default {

        data() {
            return {
                special,
                swiperOptions: {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 0,
                    slidesOffsetBefore: 0,
                },
            }
        },
        methods: {
            order( item ) {
                this.$router.push({
                    name: "user-contracts-create",
                    query: {
                        'with-offer': item.id
                    }
                });
            },

            finishSpecial() {
               this.special.get(true);
            }
        },
        components: {
            Swiper,
            SwiperSlide,
            OfferCard
        },
        computed: {
            swiper() {
                return this.$refs.swiper.swiperInstance;
            }
        },
        watch: {

        },
        mounted() {
           
        }

    }

</script>